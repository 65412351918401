import React from 'react';
import Masonry from "react-masonry-component";
import { createGlobalStyle } from 'styled-components';
import AOS from 'aos';
AOS.init();

const GlobalStyles = createGlobalStyle`
    body.hide{
          overflow-y: hidden; /* Hide vertical scrollbar */
          overflow-x: hidden; /* Hide horizontal scrollbar */
        }
`;

const Gallery= function() {
    /* lightbox1 */
    const [lighbx, setlighbx] = React.useState(false);
    const handleBtnClick = () => {
      setlighbx(!lighbx);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "hidden";
    };
    const handleBtnClickclose = () => {
      setlighbx(!lighbx);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "auto";
    };

    /* lightbox2 */
    const [lighbx1, setlighbx1] = React.useState(false);
    const handleBtnClick1 = () => {
      setlighbx1(!lighbx1);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "hidden";
    };
    const handleBtnClickclose1 = () => {
      setlighbx1(!lighbx1);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "auto";
    };

    /* lightbox2 */
    const [lighbx2, setlighbx2] = React.useState(false);
    const handleBtnClick2 = () => {
      setlighbx2(!lighbx2);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "hidden";
    };
    const handleBtnClickclose2 = () => {
      setlighbx2(!lighbx2);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "auto";
    };

        /* lightbox3 */
        const [lighbx3, setlighbx3] = React.useState(false);
        const handleBtnClick3 = () => {
          setlighbx3(!lighbx3);
          var x = document.getElementsByTagName("BODY")[0];
          x.style.overflow = "hidden";
        };
        const handleBtnClickclose3 = () => {
          setlighbx3(!lighbx3);
          var x = document.getElementsByTagName("BODY")[0];
          x.style.overflow = "auto";
        };

            /* lightbox4 */
            const [lighbx4, setlighbx4] = React.useState(false);
            const handleBtnClick4 = () => {
              setlighbx4(!lighbx4);
              var x = document.getElementsByTagName("BODY")[0];
              x.style.overflow = "hidden";
            };
            const handleBtnClickclose4 = () => {
              setlighbx4(!lighbx4);
              var x = document.getElementsByTagName("BODY")[0];
              x.style.overflow = "auto";
            };
                        /* lightbox5 */
                        const [lighbx5, setlighbx5] = React.useState(false);
                        const handleBtnClick5 = () => {
                          setlighbx5(!lighbx5);
                          var x = document.getElementsByTagName("BODY")[0];
                          x.style.overflow = "hidden";
                        };
                        const handleBtnClickclose5 = () => {
                          setlighbx5(!lighbx5);
                          var x = document.getElementsByTagName("BODY")[0];
                          x.style.overflow = "auto";
                        };
                                                /* lightbox6 */
                        const [lighbx6, setlighbx6] = React.useState(false);
                        const handleBtnClick6 = () => {
                          setlighbx6(!lighbx6);
                          var x = document.getElementsByTagName("BODY")[0];
                          x.style.overflow = "hidden";
                        };
                        const handleBtnClickclose6 = () => {
                          setlighbx6(!lighbx6);
                          var x = document.getElementsByTagName("BODY")[0];
                          x.style.overflow = "auto";
                        };
                            /* lightbox7 */
                        const [lighbx7, setlighbx7] = React.useState(false);
                        const handleBtnClick7 = () => {
                          setlighbx7(!lighbx7);
                          var x = document.getElementsByTagName("BODY")[0];
                          x.style.overflow = "hidden";
                        };
                        const handleBtnClickclose7 = () => {
                          setlighbx7(!lighbx7);
                          var x = document.getElementsByTagName("BODY")[0];
                          x.style.overflow = "auto";
                        };
    return(
            <div className="container">
            <GlobalStyles/>
                <div className="row">
                    <div className="col-md-12 text-center">
                        <h2>Our Portfolio</h2>
                        <div className="space-border"></div>
                    </div>
                </div>
                 <Masonry
                    className={"row my-gallery-class"} elementType={"div"}>
                    <div className="col-lg-4 image-element-class de_modal de_modal" onClick={handleBtnClick}
                        data-aos="fade-up"
                        data-aos-once="true"
                        >
                        <div className="card-image-1">
                            <div className="d-text">
                                <h3>Zalez EVO Mint Site</h3>
                                <h5 className="d-tag">Mint Site</h5>
                            </div>
                            <img src="./img/gallery/1.jpg" alt="gallery"/>
                        </div>
                    </div>
                    <div className="col-lg-4 image-element-class de_modal" onClick={handleBtnClick1}
                        data-aos="fade-up"
                        data-aos-once="true"
                        > 
                        <div className="card-image-1">
                            <div className="d-text">
                                <h3>Bet Bunny Mint Site</h3>
                                <h5 className="d-tag">Mint Site</h5>
                            </div>
                            <img src="./img/gallery/2.jpg" alt="gallery"/>
                        </div>
                    </div>
                    <div className="col-lg-4 image-element-class de_modal" onClick={handleBtnClick2}
                        data-aos="fade-up"
                        data-aos-once="true"
                        >
                        <div className="card-image-1">
                            <div className="d-text">
                                <h3>Buff Chance Art Creation + Metadata Generation</h3>
                                <h5 className="d-tag">Art Creation</h5>
                            </div>
                            <img src="./img/gallery/439.png" alt="gallery"/>
                            <img src="./img/gallery/497.png" alt="gallery"/>
                        </div>
                    </div>
                    <div className="col-lg-4 image-element-class de_modal" onClick={handleBtnClick3}
                        data-aos="fade-up"
                        data-aos-once="true"
                        >
                        <div className="card-image-1">
                            <div className="d-text">
                                <h3>Zalez EVO Art Creation + Metadata Generation</h3>
                                <h5 className="d-tag">Art Creation</h5>
                            </div>
                            <img src="./img/gallery/31.png" alt="gallery"/>
                        </div>
                    </div>
                    <div className="col-lg-4 image-element-class de_modal" onClick={handleBtnClick4}
                        data-aos="fade-up"
                        data-aos-once="true"
                        >
                        <div className="card-image-1">
                            <div className="d-text">
                                <h3>The Ascended Trait Creation</h3>
                                <h5 className="d-tag">Art Creation</h5>
                            </div>
                            <img src="./img/gallery/omerta_male_tshirt.png" alt="gallery"/>
                        </div>
                    </div>
                    <div className="col-lg-4 image-element-class de_modal" onClick={handleBtnClick5}
                        data-aos="fade-up"
                        data-aos-once="true"
                        >
                        <div className="card-image-1">
                            <div className="d-text">
                                <h3>Trusted Goats Sample Art</h3>
                                <h5 className="d-tag">Art Creation</h5>
                            </div>
                            <img src="./img/gallery/59.png" alt="gallery"/>
                        </div>
                    </div>
                    <div className="col-lg-4 image-element-class de_modal" onClick={handleBtnClick6}
                        data-aos="fade-up"
                        data-aos-once="true"
                        >
                        <div className="card-image-1">
                            <div className="d-text">
                                <h3>Diamond Investment Group</h3>
                                <h5 className="d-tag">Art Creation</h5>
                            </div>
                            <img src="./img/gallery/Pharaoh.png" alt="gallery"/>
                        </div>
                    </div>
                    <div className="col-lg-4 image-element-class de_modal" onClick={handleBtnClick7}
                        data-aos="fade-up"
                        data-aos-once="true"
                        >
                        <div className="card-image-1">
                            <div className="d-text">
                                <h3>Easy Elves</h3>
                                <h5 className="d-tag">Art Creation</h5>
                            </div>
                            <img src="./img/gallery/eef.jpeg" alt="gallery"/>
                        </div>
                    </div>
                  </Masonry>

                  {/* lightbox1 */}
                  {lighbx && ( 
                  <div className="LightboxGal">
                    <div className="closeGal">
                        <button className="button-close" onClick={handleBtnClickclose}></button>
                    </div>
                    <div className="v-center w-100">
                      <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <img src="./img/gallery-big/1.jpg" alt="" className="img-fluid"/>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Zalez EVO Mint Site</h3>
                                <p>Zalez EVO Mint Site</p>
                               
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa fa-user-o"></i>Client: <span>Zalez</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa fa-file-text-o"></i>Type: <span>Mint site</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa fa-calendar-o"></i>Year: <span>2022</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa fa-external-link"></i>Preview: <span><a href="https://zalez-evo.vercel.app/" target="_blank" rel="noreferrer">zalez-evo.vercel.app</a></span>
                                    </div>
                                </div>
                                <div className="spacer-30"></div>
                                <blockquote>
                                    
                                    <span></span>
                                </blockquote>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  )}

                  {/* lightbox2 */}
                  {lighbx1 && ( 
                  <div className="LightboxGal">
                    <div className="closeGal">
                        <button className="button-close" onClick={handleBtnClickclose1}></button>
                    </div>
                    <div className="v-center w-100">
                      <div className="mainLightbox container">
                      <div className="row g-5">
                            <div className="col-lg-8">
                                <img src="./img/gallery-big/2.jpg" alt="" className="img-fluid"/>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Bet bunny Mint Site</h3>
                                <p>Buff Bet is a collection of 500 NFTs connected to the Buff Bunny, Zalez and Space Warlock collections. Each NFT is entitled to the revenue generated from a shared casino platform.</p>
                               
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa fa-user-o"></i>Client: <span>Buff Bunny</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa fa-file-text-o"></i>Type: <span>Launchpad</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa fa-calendar-o"></i>Year: <span>2023</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa fa-external-link"></i>Preview: <span><a href="https://bet-bunny.zalezlabs.xyz/" target="_blank" rel="noreferrer">bet-bunny.zalezlabs.xyz</a></span>
                                    </div>
                                </div>
                                <div className="spacer-30"></div>
                              
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  )}

                {/* lightboxvideo */}
                {lighbx2 && ( 
                  <div className="LightboxGal">
                    <div className="closeGal">
                        <button className="button-close" onClick={handleBtnClickclose2}></button>
                    </div>
                    <div className="v-center w-100">
                    <div className="mainLightbox container">
                      <div className="row g-5">
                            <div className="col-lg-8">
                                <img src="./img/gallery/439.png" alt="" className="img-fluid"/>
                                <img src="./img/gallery/497.png" alt="" className="img-fluid"/>
                                <img src="./img/gallery/470.png" alt="" className="img-fluid"/>
                                <img src="./img/gallery/420.png" alt="" className="img-fluid"/>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Buff Chance Art Creation + Metadata Generation </h3>
                                <p>this is one of the ART examples we've worked with, they took the Art Creation + Metadata Generation + Mint Site Package</p>
                               
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa fa-user-o"></i>Client: <span>Buff Bunny</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa fa-file-text-o"></i>Type: <span> Art Creation + Metadata Generation</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa fa-calendar-o"></i>Year: <span>2023</span>
                                    </div>
                                    
                                </div>
                                <div className="spacer-30"></div>
                            
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  )}
                                 {/* lightboxvideo */}
                {lighbx3 && ( 
                  <div className="LightboxGal">
                    <div className="closeGal">
                        <button className="button-close" onClick={handleBtnClickclose3}></button>
                    </div>
                    <div className="v-center w-100">
                    <div className="mainLightbox container">
                      <div className="row g-5">
                            <div className="col-lg-8">
                                <img src="./img/gallery/31.png" alt="" className="img-fluid"/>
                                <img src="./img/gallery/188.png" alt="" className="img-fluid"/>
                                <img src="./img/gallery/191.png" alt="" className="img-fluid"/>
                                <img src="./img/gallery/554.png" alt="" className="img-fluid"/>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Zalez EVO Art Creation + Metadata Generation </h3>
                                <p>this is one of the ART examples we've worked with, they took the Art Creation + Metadata Generation + Mint Site Package</p>
                               
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa fa-user-o"></i>Client: <span>Zalez EVO</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa fa-file-text-o"></i>Type: <span> Art Creation + Metadata Generation</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa fa-calendar-o"></i>Year: <span>2023</span>
                                    </div>
                                    
                                </div>
                                <div className="spacer-30"></div>
                            
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  )}
                {lighbx4 && ( 
                  <div className="LightboxGal">
                    <div className="closeGal">
                        <button className="button-close" onClick={handleBtnClickclose4}></button>
                    </div>
                    <div className="v-center w-100">
                    <div className="mainLightbox container">
                      <div className="row g-5">
                            <div className="col-lg-8">
                                <img src="./img/gallery/omerta_male_tshirt.png" alt="" className="img-fluid"/>
                                <img src="./img/gallery/Omerta_female_tshirt.png" alt="" className="img-fluid"/>
                                <img src="./img/gallery/omerta_female_hoodie.png" alt="" className="img-fluid"/>
                                <img src="./img/gallery/omerta_male_hoodie.png" alt="" className="img-fluid"/>
                                <img src="./img/gallery/1.png" alt="" className="img-fluid"/>
                                <img src="./img/gallery/2.png" alt="" className="img-fluid"/>
                                <img src="./img/gallery/3.png" alt="" className="img-fluid"/>
                                <img src="./img/gallery/4.png" alt="" className="img-fluid"/>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>The Ascended Trait Creation </h3>
                                <p>This is one of our Art Creation works, we made a trait for The Ascended Collection for their Marketplace Trait.</p>
                               
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa fa-user-o"></i>Client: <span>The Ascended Seven</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa fa-file-text-o"></i>Type: <span> Art Creation</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa fa-calendar-o"></i>Year: <span>2023</span>
                                    </div>
                                    
                                </div>
                                <div className="spacer-30"></div>
                            
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  )}
                                  {lighbx5 && ( 
                  <div className="LightboxGal">
                    <div className="closeGal">
                        <button className="button-close" onClick={handleBtnClickclose5}></button>
                    </div>
                    <div className="v-center w-100">
                    <div className="mainLightbox container">
                      <div className="row g-5">
                            <div className="col-lg-8">
                                <img src="./img/gallery/62.png" alt="" className="img-fluid"/>
                                <img src="./img/gallery/61.png" alt="" className="img-fluid"/>
                                <img src="./img/gallery/59.png" alt="" className="img-fluid"/>
                                
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Trusted Goat Sample Art </h3>
                                <p>This is sample art from Trusted Goat, they asked us to make a 1/1 sample art.</p>
                               
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa fa-user-o"></i>Client: <span>Trusted Goat</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa fa-file-text-o"></i>Type: <span> Art Creation</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa fa-calendar-o"></i>Year: <span>2023</span>
                                    </div>
                                    
                                </div>
                                <div className="spacer-30"></div>
                            
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  )}
                {lighbx6 && ( 
                  <div className="LightboxGal">
                    <div className="closeGal">
                        <button className="button-close" onClick={handleBtnClickclose6}></button>
                    </div>
                    <div className="v-center w-100">
                    <div className="mainLightbox container">
                      <div className="row g-5">
                            <div className="col-lg-8">
                                <img src="./img/gallery/Pharaoh.png" alt="" className="img-fluid"/>
                                <img src="./img/gallery/Astro.png" alt="" className="img-fluid"/>
                                <img src="./img/gallery/Skeleton.png" alt="" className="img-fluid"/>
                                
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Diamond Investment Group </h3>
                                <p>This is  art from Diamond Investment Group, they asked us to make a 1/1  art.</p>
                               
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa fa-user-o"></i>Client: <span>Diamond Investment Group</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa fa-file-text-o"></i>Type: <span> Art Creation</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa fa-calendar-o"></i>Year: <span>2023</span>
                                    </div>
                                    
                                </div>
                                <div className="spacer-30"></div>
                            
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  )}
                                  {lighbx7 && ( 
                  <div className="LightboxGal">
                    <div className="closeGal">
                        <button className="button-close" onClick={handleBtnClickclose7}></button>
                    </div>
                    <div className="v-center w-100">
                    <div className="mainLightbox container">
                      <div className="row g-5">
                            <div className="col-lg-8">
                                <img src="./img/gallery/eef.jpeg" alt="" className="img-fluid"/>
                                <img src="./img/gallery/eef3.jpeg" alt="" className="img-fluid"/>
                                   
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Easy Elves Sample Art </h3>
                                <p>This is  Sample Art we made for Easy Elves</p>
                               
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa fa-user-o"></i>Client: <span>Easy Elves</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa fa-file-text-o"></i>Type: <span> Art Creation</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa fa-calendar-o"></i>Year: <span>2023</span>
                                    </div>
                                    
                                </div>
                                <div className="spacer-30"></div>
                            
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  )}


      </div>
    );
}

export default Gallery;