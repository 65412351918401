import React from 'react';

const footer = () => {
    return(
        <footer>
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <a href="https://twitter.com/zalez_ale" target="_blank" rel="noreferrer">
                            <span className="copy">&copy; Copyright 2023 - Zalezlabs</span>
                        </a>
                    </div>
                    <div className="col-md-6">
                        <div className="social-icons">
                           
                            <a href="https://twitter.com/zalez_ale" rel="noreferrer"><i className="fa fa-twitter"></i></a>
                            
                            
                            
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default footer;